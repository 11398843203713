import React, { useCallback, useEffect, useState } from 'react'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Flex, Image, Layout, Spin, Upload, UploadProps } from 'antd'
import axios from 'axios'
import { API_URL, AppConstant } from 'AppConstant'
import { calculateAge } from 'utils/Dob'
import { getUserTimezone } from 'utils/GetUserTimezone'
import {
  CenteredContainer,
  Container,
  HeaderText,
  NameText,
  NameWrapper,
  NoScansToUpload,
  Span,
  UploadContainer,
} from './scan.styled'
import KiroLogo from '../../../images/kiro-logo-full.svg'
import logout from '../../../images/logout.svg'
import tickSvg from '../../../images/tick.svg'
import uploadSvg from '../../../images/upload.svg'
import { getTodayScanListAsync, updateScanList } from '../../../redux/scan'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { APIStatus } from '../../../redux/types'

type CurrentScanUpload = {
  patientId: string | null
  visitId: string | null
  clinicId: string | null
  profilePic: string | null
  firstname: string | null
  lastname: string | null
  dob: string | null
}

export default function Scan() {
  const { Header, Content } = Layout
  const [currentScanUpload, setCurrentScanUpload] = useState<CurrentScanUpload>({
    patientId: null,
    visitId: null,
    clinicId: null,
    profilePic: null,
    dob: null,
    firstname: null,
    lastname: null,
  })
  const dispatch = useAppDispatch()
  const todayScan = useAppSelector((state) => state.scan.scanList)
  const todayScanStatus = useAppSelector((state) => state.scan.scanListStatus)

  function signOut() {
    localStorage.removeItem(AppConstant.ACCESS_TOKEN_SCAN_AUTH)
    localStorage.removeItem(AppConstant.CLINIC_ID)
    window.location.href = '/scan/auth'
  }

  useEffect(() => {
    dispatch(getTodayScanListAsync({ timezone: getUserTimezone() }))
  }, [])

  useEffect(() => {
    if (todayScan && todayScan.length > 0) {
      setCurrentScanUpload((prev) => ({
        ...prev,
        visitId: todayScan[0].id,
        patientId: todayScan[0].patientId,
        clinicId: todayScan[0].clinicId,
        profilePic: todayScan[0].patient.user.profilePic,
        firstname: todayScan[0].patient.user.firstname,
        lastname: todayScan[0].patient.user.lastname,
        dob: todayScan[0].patient.user.dob,
      }))
    }
  }, [todayScan])

  if (todayScanStatus === APIStatus.pending) {
    return (
      <div
        style={{ height: '100vh', width: '100vw', overflow: 'hidden', touchAction: 'none', WebkitUserSelect: 'none' }}
      >
        <Layout style={{ width: '100%', height: '100%' }}>
          <Header
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid #13152D',
              position: 'fixed',
              width: '100%',
              paddingLeft: 30,
              paddingRight: 12,
              // top: 0,
            }}
          >
            <Image preview={false} onClick={() => {}} src={KiroLogo}></Image>
            <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }} onClick={signOut}>
              <img src={logout} style={{ cursor: 'pointer' }}></img>
              <div
                style={{ marginLeft: '8px', color: '#007f7c', cursor: 'pointer', fontSize: '16px', fontWeight: 600 }}
              >
                Logout
              </div>
            </div>
          </Header>
          <Content
            style={{
              backgroundColor: '#050624',
              padding: '20px 48px',
              position: 'fixed',
              width: '100%',
              top: 100,
              height: '100%',
              display: 'flex',
              gap: '80px',
            }}
          >
            <CenteredContainer>
              <Spin></Spin>
            </CenteredContainer>
          </Content>
        </Layout>
      </div>
    )
  } else if (todayScan && todayScan.length === 0) {
    return (
      <Layout style={{ width: '100%', height: '100%' }}>
        <div
          style={{ height: '100vh', width: '100vw', overflow: 'hidden', touchAction: 'none', WebkitUserSelect: 'none' }}
        >
          <Layout style={{ width: '100%', height: '100%' }}>
            <Header
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '1px solid #13152D',
                position: 'fixed',
                width: '100%',
                paddingLeft: 30,
                paddingRight: 12,
              }}
            >
              <Image preview={false} onClick={() => {}} src={KiroLogo}></Image>
              <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }} onClick={signOut}>
                <img src={logout} style={{ cursor: 'pointer' }}></img>
                <div
                  style={{ marginLeft: '8px', color: '#007f7c', cursor: 'pointer', fontSize: '16px', fontWeight: 600 }}
                >
                  Logout
                </div>
              </div>
            </Header>
            <Content
              style={{
                backgroundColor: '#050624',
                padding: '20px 48px',
                position: 'fixed',
                width: '100%',
                top: 100,
                height: '100%',
                display: 'flex',
                gap: '80px',
              }}
            >
              <NoScansToUpload>No scans available for upload</NoScansToUpload>
            </Content>
          </Layout>
        </div>
      </Layout>
    )
  } else {
    return (
      <div
        style={{ height: '100vh', width: '100vw', overflow: 'hidden', touchAction: 'none', WebkitUserSelect: 'none' }}
      >
        <Layout style={{ width: '100%', height: '100%' }}>
          <Header
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid #13152D',
              position: 'fixed',
              width: '100%',
              paddingLeft: 30,
              paddingRight: 12,
            }}
          >
            <Image preview={false} onClick={() => {}} src={KiroLogo}></Image>
            <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }} onClick={signOut}>
              <img src={logout} style={{ cursor: 'pointer' }}></img>
              <div
                style={{ marginLeft: '8px', color: '#007f7c', cursor: 'pointer', fontSize: '16px', fontWeight: 600 }}
              >
                Logout
              </div>
            </div>
          </Header>
          <Content
            style={{
              backgroundColor: '#050624',
              padding: '20px 48px',
              position: 'fixed',
              width: '100%',
              top: 100,
              height: '100%',
              display: 'flex',
              gap: '80px',
            }}
          >
            <Flex vertical gap={8} style={{ width: '48%' }}>
              <HeaderText>Patients | {todayScan && todayScan.length > 0 ? `${todayScan.length}` : 'None'}</HeaderText>
              {todayScan && todayScan.length > 0 && (
                <Flex gap={8} vertical style={{ overflowY: 'auto', padding: '2px', height: '100%' }}>
                  {todayScan?.map((card: any) => (
                    <Card
                      style={{
                        border: currentScanUpload?.visitId === card.id ? ' 1px solid #FFFEFF' : '1px solid #2A2D50',
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      cover={
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px',
                            marginLeft: 20,
                            width: '100%',
                          }}
                        >
                          <div style={{ width: 40 }}>
                            <Avatar src={card.patient.user.profilePic} style={{ height: 40, width: 40 }}></Avatar>
                          </div>
                          <NameText>
                            {card?.patient?.user?.firstname || card?.patient?.user?.lastname
                              ? (card?.patient?.user?.firstname || '-') +
                                (card?.patient?.user?.lastname ? ' ' + card?.patient?.user?.lastname : '-')
                              : '-'}
                          </NameText>
                        </div>
                      }
                      onClick={() => {
                        if (currentScanUpload.visitId !== card.id) {
                          setCurrentScanUpload((prev) => ({
                            ...prev,
                            visitId: card.id,
                            patientId: card.patientId,
                            clinicId: card.clinicId,
                            profilePic: card.patient.user.profilePic,
                            firstname: card.patient.user.firstname,
                            lastname: card.patient.user.lastname,
                            dob: card.patient.user.dob,
                          }))
                        }
                      }}
                      key={card.id}
                    ></Card>
                  ))}
                </Flex>
              )}
            </Flex>
            <Flex style={{ width: '48%' }}>
              <ScanUploadModal patientInfo={currentScanUpload}></ScanUploadModal>
            </Flex>
          </Content>
        </Layout>
      </div>
    )
  }
}

export function ScanUploadModal({ patientInfo }: { patientInfo: CurrentScanUpload }) {
  const dispatch = useAppDispatch()
  const todayScanStatus = useAppSelector((state) => state.scan.scanListStatus)
  const todayScan = useAppSelector((state) => state.scan.scanList)
  const [fileUploaded, setFileUpdloaded] = useState(false)
  const [disableUpload, setDisableUpload] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (fileUploaded) {
      const timeout = setTimeout(() => {
        setDisableUpload(false)
        dispatch(getTodayScanListAsync({ timezone: getUserTimezone() }))
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [fileUploaded])

  useEffect(() => {
    if (fileUploaded) {
      setFileUpdloaded(false)
    }
  }, [todayScan])

  useEffect(() => {
    if (error !== '') {
      const timer = setTimeout(() => {
        setError('')
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [error])

  const handleFileChange: UploadProps['onChange'] = (info) => {
    setDisableUpload(true)
    console.log(info, 'total info')
  }

  const props: UploadProps = {
    customRequest: async ({ file }) => {
      let signedUrlResponse
      let uploadImageRes
      try {
        const response = await axios.post(
          `${API_URL}/scan-request/signed-url-generate`,
          {
            visitId: patientInfo.visitId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(AppConstant.ACCESS_TOKEN_SCAN_AUTH)}`,
              'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
            },
          },
        )

        signedUrlResponse = response

        if (response.data.code !== 'success') {
          alert('Could not generate upload URl, Please try again later')
          setError('signedUrlNotFound')
          setDisableUpload(false)
          return
        }
      } catch (error: any) {
        if (error?.code === 'ERR_NETWORK') {
          setDisableUpload(false)
          setError('failedToUpload')
          alert('Network error: Please check your internet connection or try again later.')
        } else if (error?.response) {
          setDisableUpload(false)
          setError('failedToUpload')
          if (error?.response?.status) {
            alert(`Error Code: ${error?.response?.status} An unexpected error occurred, Please try again later`)
          }
        } else {
          setDisableUpload(false)
          setError('failedToUpload')
          alert('An unexpected error occurred')
        }
        return
      }

      try {
        const signedUrl =
          signedUrlResponse?.data && signedUrlResponse?.data?.data && signedUrlResponse?.data?.data?.uploadUrl

        const uploadImageResponse = await axios.put(signedUrl, file, {
          headers: {
            'Content-Type': 'image/jpeg',
          },
        })

        uploadImageRes = uploadImageResponse

        if (uploadImageResponse.status !== 200 && uploadImageResponse.status !== 201) {
          alert('Failed to upload image, Please try again later')
          setError('failedToUpload')
          setDisableUpload(false)
          return
        }
      } catch (error: any) {
        if (error?.code === 'ERR_NETWORK') {
          setDisableUpload(false)
          setError('failedToUpload')
          alert('Network error: Please check your internet connection or try again later.')
        } else if (error?.response) {
          setDisableUpload(false)
          setError('failedToUpload')
          if (error?.response?.status) {
            alert(`Error Code: ${error?.response?.status} An unexpected error occurred, Please try again later`)
          }
        } else {
          setDisableUpload(false)
          setError('failedToUpload')
          alert('An unexpected error occurred')
        }
        return
      }

      try {
        const updateStatus = uploadImageRes?.status === 200 || uploadImageRes?.status === 201 ? 'completed' : 'failed'

        const updateScanStatusRes = await axios.put(
          `${API_URL}/scan-request/visit/${patientInfo.visitId}`,
          {
            status: updateStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(AppConstant.ACCESS_TOKEN_SCAN_AUTH)}`,
              'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
            },
          },
        )

        if (updateScanStatusRes.data.code !== 'success') {
          setFileUpdloaded(true)
        }
        if (updateScanStatusRes.data.code === 'success') {
          setFileUpdloaded(true)
        }
      } catch (error: any) {
        if (error?.code === 'ERR_NETWORK') {
          setDisableUpload(false)
          setFileUpdloaded(true)
          alert('Network error: Please check your internet connection or try again later.')
        } else if (error?.response) {
          setDisableUpload(false)
          setFileUpdloaded(true)
          if (error?.response?.status) {
            alert(`Error Code: ${error?.response?.status} An unexpected error occurred, Please try again later`)
          }
        } else {
          setDisableUpload(false)
          setFileUpdloaded(true)
          alert('An unexpected error occurred')
        }
      }
    },
    onChange: handleFileChange,
    multiple: true,
  }

  if (todayScanStatus === APIStatus.pending) {
    return (
      <Container
        style={{
          height: '40%',
          width: '100%',
          background: '#10123d',
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin></Spin>
      </Container>
    )
  } else {
    return (
      <Container>
        <Flex style={{ padding: '30px', flexDirection: 'column' }}>
          <Flex gap={20}>
            <Avatar
              shape="square"
              style={{ borderRadius: 8, minWidth: '160px', minHeight: '160px' }}
              icon={patientInfo.profilePic ? <img src={patientInfo.profilePic ?? ''}></img> : <UserOutlined />}
            />
            <Flex gap={20} style={{ flexDirection: 'column' }}>
              <NameWrapper>
                <Span style={{ textTransform: 'uppercase' }}>{patientInfo.firstname ?? '-'}</Span>
                <Span style={{ textTransform: 'uppercase' }}>{patientInfo.lastname ?? '-'}</Span>
              </NameWrapper>
              <Span>{'Age' + ' ' + calculateAge(patientInfo.dob)}</Span>
            </Flex>
          </Flex>
          <UploadContainer>
            <Upload.Dragger
              {...props}
              onChange={handleFileChange}
              showUploadList={false}
              maxCount={1}
              style={{ border: 'none', backgroundColor: '#2a2d56' }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button
                  children={error === '' ? (fileUploaded ? 'Scan Uploaded' : 'Upload Scan') : 'Upload Failed'}
                  style={{
                    minWidth: '12.1rem',
                    paddingLeft: error === '' ? (fileUploaded ? '1.5rem' : '2.5rem') : '1.5rem',
                    height: '42px',
                    backgroundColor: error === '' ? (fileUploaded ? '#fffeff' : '#007f7c') : '#E33939',
                    backgroundImage: `url(${error === '' ? (fileUploaded ? 'none' : uploadSvg) : 'none'})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '15%  center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: disableUpload && !fileUploaded ? 0.5 : 1,
                    color: error === '' ? (fileUploaded ? '#007f7c' : '#fffeff') : '#fffeff',
                  }}
                  disabled={disableUpload}
                ></Button>
              </div>
            </Upload.Dragger>
          </UploadContainer>
        </Flex>
      </Container>
    )
  }
}
